<template>
  <div class="bonuses-details">
    <div v-for="(property, index) in props.items" :key="index" class="bonuses-details__row">
      <span class="bonuses-details__label">{{ property.label }}</span>
      <span class="bonuses-details__value">{{ property.value }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    items: { label: string; value: string | number }[];
  }>();
</script>

<style src="~/assets/styles/components/bonuses/details.scss" lang="scss" />
