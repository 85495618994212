<template>
  <vue-final-modal class="modal-deposit-bonus-detail">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.bonusDetailsTitle') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('deposit-bonus-detail')" />
      </div>
    </div>

    <div class="vfm__body">
      <card-deposit-bonus :bonusInfo="props.bonusInfo" hideButton />
      <bonuses-details :items="bonusDetails" />

      <div v-if="props.unavailable" class="modal-deposit-bonus-detail__available">
        <div
          v-if="props.availableDepositLabel"
          class="modal-deposit-bonus-detail__available-deposit"
          v-html="props.availableDepositLabel"
        />

        <div v-else-if="props.availableAt" class="modal-deposit-bonus-detail__available-timer">
          {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel') }}

          <bonuses-timer :expiredAt="props.availableAt" size="sm" />
        </div>
      </div>

      <button-base v-else class="modal-deposit-bonus-detail__button" type="primary" size="md" @click="clickAction">
        {{
          getContent(
            globalComponentsContent,
            defaultLocaleGlobalComponentsContent,
            'bonuses.depositBonus.depositButton'
          )
        }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { IBonus } from '~/types';

  const props = defineProps<{
    bonusInfo: IBonus[];
    unavailable?: boolean;
    availableAt?: string;
    availableDepositLabel?: string;
  }>();

  const { getContent, getMinBonusDeposit, formatAmount, getEquivalentFromBase } = useProjectMethods();
  const { openWalletModal, closeModal } = useModalStore();
  const bonusStore = useBonusStore();
  const { walletDepositBonus } = storeToRefs(bonusStore);
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const walletStore = useWalletStore();
  const { activeAccount } = storeToRefs(walletStore);

  const getPropLabel = (bonusType: string, fieldKey: string): string => {
    return getContent(
      globalComponentsContent,
      defaultLocaleGlobalComponentsContent,
      `bonuses.${bonusType}.${fieldKey}`
    );
  };

  const minDeposit = getMinBonusDeposit(props.bonusInfo[0]);
  const minDepositValue = formatAmount(minDeposit?.currency, minDeposit?.amount);

  const getMaxWinAmount = (): { maxWinCash: string | undefined; maxWinFreeSpins: string | undefined } => {
    let maxWinCashBonus = 0;
    let maxWinFreeSpins = 0;

    props.bonusInfo.forEach(bonus => {
      if ((bonus.type === 3 && maxWinFreeSpins) || (bonus.type !== 3 && maxWinCashBonus)) return;

      const maxWinAmountItems = bonus.maxWinAmountItems;
      const maxWinAmountBase = bonus.baseCurrencyMaxWinAmount;
      const exclusionItem = maxWinAmountItems?.find(item => item.currency === activeAccount.value?.currency);
      let maxWinSum: number = 0;

      if (exclusionItem) maxWinSum = exclusionItem.amount;
      else if (maxWinAmountBase) {
        const { amount } = getEquivalentFromBase(maxWinAmountBase, activeAccount.value?.currency);
        maxWinSum = amount;
      }

      if (bonus.type === 3) maxWinFreeSpins = maxWinSum;
      else maxWinCashBonus = maxWinSum;
    });

    return {
      maxWinCash: maxWinCashBonus ? formatAmount(activeAccount.value?.currency, maxWinCashBonus) : undefined,
      maxWinFreeSpins: maxWinFreeSpins ? formatAmount(activeAccount.value?.currency, maxWinFreeSpins) : undefined,
    };
  };

  const getMaxBetAmount = (): { maxBetCash: string | undefined; maxBetFreeSpins: string | undefined } => {
    let maxBetCashBonus = 0;
    let maxBetFreeSpins = 0;

    props.bonusInfo.forEach(bonus => {
      if ((bonus.type === 3 && maxBetFreeSpins) || (bonus.type !== 3 && maxBetCashBonus)) return;

      const maxBetAmountItems = bonus.maxBetAmountItems;
      const maxBetAmountBase = bonus.baseCurrencyMaxBetAmount;
      const exclusionItem = maxBetAmountItems?.find(item => item.currency === activeAccount.value?.currency);
      let maxBetSum: number = 0;

      if (exclusionItem) maxBetSum = exclusionItem.amount;
      else if (maxBetAmountBase) {
        const { amount } = getEquivalentFromBase(maxBetAmountBase, activeAccount.value?.currency);
        maxBetSum = amount;
      }

      if (bonus.type === 3) maxBetFreeSpins = maxBetSum;
      else maxBetCashBonus = maxBetSum;
    });

    return {
      maxBetCash: maxBetCashBonus ? formatAmount(activeAccount.value?.currency, maxBetCashBonus) : undefined,
      maxBetFreeSpins: maxBetFreeSpins ? formatAmount(activeAccount.value?.currency, maxBetFreeSpins) : undefined,
    };
  };

  const getBonusWager = (): {
    cashWagerCasino: string | undefined;
    freeSpinsWagerCasino: string | undefined;
    cashWagerSportsbook: string | undefined;
    freeSpinsWagerSportsbook: string | undefined;
  } => {
    let cashWagerCasino: string | undefined;
    let freeSpinsWagerCasino: string | undefined;
    let cashWagerSportsbook: string | undefined;
    let freeSpinsWagerSportsbook: string | undefined;

    props.bonusInfo.forEach(bonus => {
      if (bonus.type === 3) {
        if (bonus.wagerCasino && !freeSpinsWagerCasino) freeSpinsWagerCasino = `x${bonus.wagerCasino}`;
        if (bonus.wagerSportsbook && !freeSpinsWagerSportsbook) freeSpinsWagerSportsbook = `x${bonus.wagerSportsbook}`;
      } else {
        if (bonus.wagerCasino && !cashWagerCasino) cashWagerCasino = `x${bonus.wagerCasino}`;
        if (bonus.wagerSportsbook && !cashWagerSportsbook) cashWagerSportsbook = `x${bonus.wagerSportsbook}`;
      }
    });

    return { cashWagerCasino, freeSpinsWagerCasino, cashWagerSportsbook, freeSpinsWagerSportsbook };
  };

  const bonusDetails = computed(() => {
    const detailsArr: { label: string; value: string }[] = [];

    const { cashWagerCasino, freeSpinsWagerCasino, cashWagerSportsbook, freeSpinsWagerSportsbook } = getBonusWager();
    if (cashWagerCasino) {
      detailsArr.push({
        label: getPropLabel('cashBonus', 'casinoWagerLabel'),
        value: cashWagerCasino,
      });
    }
    if (freeSpinsWagerCasino) {
      detailsArr.push({
        label: getPropLabel('freeSpin', 'casinoWagerLabel'),
        value: freeSpinsWagerCasino,
      });
    }
    if (cashWagerSportsbook) {
      detailsArr.push({
        label: getPropLabel('cashBonus', 'sportsbookWagerLabel'),
        value: cashWagerSportsbook,
      });
    }
    if (freeSpinsWagerSportsbook) {
      detailsArr.push({
        label: getPropLabel('freeSpin', 'sportsbookWagerLabel'),
        value: freeSpinsWagerSportsbook,
      });
    }

    if (minDepositValue) {
      detailsArr.push({
        label: getPropLabel('depositBonus', 'minDepositLabel'),
        value: minDepositValue,
      });
    }

    const { maxBetCash, maxBetFreeSpins } = getMaxBetAmount();
    if (maxBetCash) {
      detailsArr.push({
        label: getPropLabel('cashBonus', 'maxBetLabel'),
        value: maxBetCash,
      });
    }
    if (maxBetFreeSpins) {
      detailsArr.push({
        label: getPropLabel('freeSpin', 'maxBetLabel'),
        value: maxBetFreeSpins,
      });
    }

    const { maxWinCash, maxWinFreeSpins } = getMaxWinAmount();
    if (maxWinCash) {
      detailsArr.push({
        label: getPropLabel('cashBonus', 'maxWinLabel'),
        value: maxWinCash,
      });
    }
    if (maxWinFreeSpins) {
      detailsArr.push({
        label: getPropLabel('freeSpin', 'maxWinLabel'),
        value: maxWinFreeSpins,
      });
    }

    return detailsArr;
  });

  const clickAction = async (): Promise<void> => {
    const minDeposit = getMinBonusDeposit(props.bonusInfo[0]);
    walletDepositBonus.value = {
      id: props.bonusInfo[0].id,
      packageId: props.bonusInfo[0].package?.id,
      amount: minDeposit?.amount,
    };

    await openWalletModal();
    await closeModal('deposit-bonus-detail');
  };
</script>

<style src="~/assets/styles/components/modal/deposit-bonus-detail.scss" lang="scss" />
